import React, { useState } from "react"
import { useForm } from "react-hook-form"
import createGoogleSheetDataAPI from "../../functions/createGoogleSheetDataAPI"
import HelpText from "../HelpText"

const Subscribe = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [isHelpText, setIsHelpText] = useState("")

  const onSubmit = async data => {
    try {
      const { email } = data

      const inboundBody = {
        time: new Date().toLocaleString("zh-TW"),
        email,
      }

      const result = await createGoogleSheetDataAPI(
        process.env.GATSBY_SUBSCRIBE_URL,
        inboundBody
      )

      if (result.status === 200) {
        await reset()
        setIsHelpText("訂閱成功")
      } else {
        setIsHelpText("發生錯誤! 請加line聯繫我們")
      }
    } catch (e) {
      alert("發生錯誤! 請加line聯繫我們")
    }
  }

  return (
    <div className="mt-16 border-t-4 border-amber-400">
      <p className="text-3xl leading-tight font-bold text-neutral-800 mt-3.5 mb-8 sm:mt-4">
        訂閱電子報
      </p>
      {/* 訂閱區塊 */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-12 sm:mt-16 lg:mt-0"
      >
        <div className="flex flex-nowrap">
          <input
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "信箱格式錯誤",
              },
            })}
            placeholder="請輸入E-mail"
            className="text-neutral-400 w-full py-2.5 pl-6 border border-r-0 border-neutral-400 rounded-l-lg"
          />
          <button
            type="submit"
            className="bg-amber-400 text-white font-bold rounded-r-lg"
          >
            <p className="px-4 py-2.5 w-28 sm:px-12 sm:w-44 lg:hidden">
              訂閱電子報
            </p>
            <p className="hidden px-4 py-2.5 w-16 whitespace-nowrap lg:block">
              訂閱
            </p>
          </button>
        </div>
        {errors?.email?.type === "required" && <HelpText text="此為必填項目" />}
        {errors?.email?.type === "pattern" && (
          <HelpText text={errors.email.message} />
        )}
        <HelpText text={isHelpText} />
      </form>
    </div>
  )
}

export default Subscribe

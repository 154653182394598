import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { BlogPostJsonLd, GatsbySeo } from "gatsby-plugin-next-seo"
import Breadcrumb from "../components/Breadcrumb"
import CTA from "../components/cta"
import Layout from "../components/layout"
import { useMetadata } from "../data/use-metadata"
import Excerpt from "../components/Post/Excerpt"
import Menu from "../components/Post/Menu"
import Post from "../components/Post"
import Share from "../components/Post/Share"
import Header from "../components/Post/Header"
import PostItem from "../components/Post/PostItem"
import Sidebar from "../components/FindCoursePage/Sidebar"
import Subscribe from "../components/Post/Subscribe"
import TeacherCard from "../components/Card/TeacherCard"
import Consult from "../components/Consult"
import { useLandingPageData } from "../data/useLandingPageData"
import PostCard from "../components/Card/PostCard"
import TagWithLink from "../components/Tag/TagWithLink"
import Navigation from "../components/Navigation"

const PostTemplate = ({ data }) => {
  const {
    contentfulPost: post,
    popularPosts,
    recommendedPosts,
    tags,
    posts,
  } = data
  const {
    name,
    type,
    description: { description },
    slug: teacherSlug,
    image: { gatsbyImageData },
  } = post.author

  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const { siteUrl } = useMetadata()

  const canonical = `${siteUrl}/${post.topic.slug}/${post.slug}`
  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: `${post.topic.name}`,
      url: `/${post.topic.slug}`,
    },
    {
      name: `${post.name}`,
      url: `/${post.topic.slug}/${post.slug}`,
    },
  ]

  const getMenuData = post => {
    let subContent = []
    const menuData = post.contents
      .map(content => {
        if (content.displayTitle) {
          subContent = []

          return {
            id: content.id,
            content: content?.title,
            subContent: subContent,
          }
        } else if (content.displayTitle === undefined) {
          subContent.push({ content: content.title, id: content.id })
        }
      })
      .filter(element => element !== undefined)

    return menuData
  }

  const menuData = getMenuData(post)

  const slug = `/${post.topic.slug}/${post.slug}#content-`

  const recommendedPostsItems = recommendedPosts.nodes.map(post => (
    <PostItem key={post.id} post={post} />
  ))

  const popularPostsItems = popularPosts.nodes.map(post => (
    <PostItem key={post.id} post={post} />
  ))

  const tagsItem = tags.nodes.map(tag => <TagWithLink key={tag.id} tag={tag} />)

  let count = 0
  // 排除自己，最多四張卡片
  const postCardsExceptSelf = posts.nodes.filter(item => {
    if (item.slug !== post.slug && count < 4) {
      count++
      return true
    }
  })

  const postCards = postCardsExceptSelf.map((post, idx) => {
    // 猜你會喜歡卡片顯示邏輯 > 手機三張 平板四張 電腦版三張
    const isLast =
      postCardsExceptSelf.length > 3 && idx === postCardsExceptSelf.length - 1
    return <PostCard key={post.id} data={post} isLast={isLast} />
  })

  return (
    <Layout>
      <GatsbySeo
        title={post.name}
        description={post?.excerpt?.excerpt}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: post.name,
          description: post?.excerpt?.excerpt,
          images: [
            {
              url: `https:${post.image.file.url}`,
              alt: post.name,
            },
          ],
          type: "article",
          article: {
            publishedTime: post.createdAt,
            modifiedTime: post.updatedAt,
            tags: [post?.tags?.map(tag => tag.name)],
          },
        }}
      />
      <BlogPostJsonLd
        url={canonical}
        title={post.name}
        images={[`https:${post.image.file.url}`]}
        datePublished={post.createdAt}
        dateModified={post.updatedAt}
        authorName={post.author.name}
        description={post?.excerpt?.excerpt}
      />
      <div className="my-12 wrapper">
        <Breadcrumb crumbs={crumbs} />
      </div>
      <div className="wrapper grid grid-cols-1 lg:grid-cols-5 lg:gap-8 text-neutral-600 mt-8 sm:mt-12 lg:mt-16">
        <div className="lg:col-span-4">
          <div className="mb-6">
            {/* PostTemplate Header */}
            <Header post={post} />

            {/* PostTemplate Excerpt */}
            <Excerpt post={post} />
          </div>

          {/* PostTemplate Menu */}
          <Menu slug={slug} menuData={menuData} />

          {/* PostTemplate Content */}
          <Post post={post} />

          {/* PostTemplate CTA */}
          <CTA
            destination={post.cta.link}
            excerpt={post.cta.excerpt}
            title={post.cta.title}
            name={post.cta.name}
          />

          {/* PostTemplate Share */}
          <Share post={post} canonical={canonical} />

          {/* Author */}
          <p className="text-2xl mt-12 mb-6 !leading-loose tracking-wide font-bold text-neutral-800 sm:text-3xl">
            關於作者
          </p>
          <div className="grid grid-cols-1">
            <TeacherCard
              slug={teacherSlug}
              name={name}
              gatsbyImageData={gatsbyImageData}
              type={type}
              description={description}
              isPostPage={true}
            />
          </div>
        </div>
        <div className="lg:col-span-1">
          <Sidebar
            popularItems={popularPostsItems}
            recommendedItems={recommendedPostsItems}
            isPostPage={true}
          />
          {post.topic.slug === "training-news" && (
            <div className="mt-16 border-t-4 border-amber-400 lg:mt-0">
              <p className="text-3xl leading-tight font-bold text-neutral-800 mt-3.5 mb-3 sm:mt-4">
                主題分類
              </p>
              {tagsItem}
            </div>
          )}
          <Subscribe />
        </div>
      </div>

      {postCards.length ? (
        <div className="wrapper mt-24 lg:mt-36">
          <div className="border-t-4 border-amber-400">
            <div className="flex justify-between items-center mb-8 mt-3.5 sm:mb-12">
              <h2 className="text-3xl tracking-wide font-bold text-neutral-800 sm:text-5xl">
                猜你會喜歡
              </h2>
              <Navigation slug={post.topic.slug} text="所有文章" />
            </div>

            <ul className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {postCards}
            </ul>
          </div>
        </div>
      ) : null}

      <div className="mt-24 sm:mt-36">
        <Consult
          gatsbyImageData={consultGatsbyImageData}
          consultTitle={consultTitle}
        />
      </div>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const pageQuery = graphql`
  query Post($slug: String!, $topicSlug: String!, $firstTag: String!) {
    contentfulPost(slug: { eq: $slug }) {
      slug
      name
      updatedAt(formatString: "YYYY-MM-DD")
      createdAt(formatString: "YYYY-MM-DD")
      author {
        ... on ContentfulExternalAuthor {
          name
          type
          description {
            description
          }
          image {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              height: 144
              width: 144
            )
            file {
              url
            }
          }
        }
        ... on ContentfulTeacher {
          name
          type
          slug
          description {
            description
          }
          image {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              height: 144
              width: 144
            )
            file {
              url
            }
          }
        }
      }
      cta {
        title
        link
        excerpt
        name
      }
      excerpt {
        excerpt
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          height: 658
          width: 1171
        )
        file {
          url
        }
      }
      tags {
        id
        name
        slug
      }
      topic {
        name
        slug
        post {
          name
        }
      }
      contents {
        ... on ContentfulContent {
          id
          title
          displayTitle
          content {
            content
          }
        }
        ... on ContentfulSubContent {
          id
          title
          subContent: content {
            subContent: content
          }
        }
      }
    }
    latestPosts: allContentfulPost(
      sort: { fields: updatedAt, order: DESC }
      limit: 6
    ) {
      nodes {
        id
        slug
        name
        updatedAt(formatString: "YYYY-MM-DD")
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            height: 768
            width: 512
          )
        }
        excerpt {
          excerpt
        }
        topic {
          slug
          name
        }
      }
    }
    recommendedPosts: allContentfulPost(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: {
        topic: { slug: { eq: $topicSlug } }
        isRecommended: { eq: true }
      }
    ) {
      nodes {
        id
        name
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        topic {
          slug
        }
      }
    }
    popularPosts: allContentfulPost(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { topic: { slug: { eq: $topicSlug } }, isPopular: { eq: true } }
    ) {
      nodes {
        id
        name
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        topic {
          slug
        }
      }
    }
    tags: allContentfulTag {
      nodes {
        name
        slug
        id
      }
    }
    posts: allContentfulPost(
      filter: {
        topic: { slug: { eq: $topicSlug } }
        tags: { elemMatch: { slug: { eq: $firstTag } } }
      }
      sort: { fields: updatedAt, order: DESC }
      limit: 5
    ) {
      nodes {
        id
        slug
        name
        updatedAt(formatString: "YYYY-MM-DD")
        excerpt {
          excerpt
        }
        image {
          gatsbyImageData(
            width: 570
            height: 321
            placeholder: BLURRED
            quality: 100
          )
        }
        topic {
          name
          slug
        }
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"

const Tag = ({ name }) => {
  return <p className="leading-loose text-neutral-400"># {name}</p>
}

const Excerpt = ({ post }) => {
  const {
    excerpt: { excerpt },
  } = post

  return (
    <div className="my-12 border text-neutral-600 border-amber-400 p-6 rounded-lg">
      <p className="text-lg !leading-loose">{excerpt}</p>
    </div>
  )
}

Tag.propTypes = {
  name: PropTypes.string,
}

Excerpt.propTypes = {
  post: PropTypes.object,
}

export default Excerpt

import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"

const PostItem = ({ post }) => {
  const {
    name,
    updatedAt,
    slug,
    topic: { slug: topicSlug },
  } = post

  const postSlug = `/${topicSlug}/${slug}`

  return (
    <li className="mb-4 group">
      <Link to={postSlug}>
        <p className="mt-4 !leading-normal text-neutral-400 sm:text-lg">
          {updatedAt}
        </p>
        <p className="text-xl !leading-normal font-bold text-neutral-600 mt-2 mb-4 sm:text-2xl group-hover:text-amber-400 ease-in-out duration-300">
          {name}
        </p>
      </Link>
    </li>
  )
}

PostItem.propTypes = {
  post: PropTypes.object,
}

export default PostItem

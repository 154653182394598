import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../Link"

const Tag = ({ tag }) => {
  const { name, slug } = tag

  return (
    <Link
      to={`/training-news/tag/${slug}`}
      as="link"
      style="inline-block text-sm text-neutral-500 border border-neutral-500 rounded py-2 px-3 transition duration-300 ease-in-out hover:text-white hover:bg-amber-400 hover:border-amber-400"
    >
      {name}
    </Link>
  )
}

const Header = ({ post }) => {
  const {
    image: { gatsbyImageData },
    name,
    updatedAt,
    createdAt,
    tags,
    topic,
  } = post

  const tagItems = tags?.map(tag => {
    return <Tag key={tag.id} tag={tag} />
  })

  return (
    <>
      <h1 className="text-neutral-800 text-3xl tracking-wide font-bold !leading-normal sm:text-5xl">
        {name}
      </h1>
      <div
        className={`sm:flex sm:items-center ${
          topic.slug !== "training-news" ? "" : "sm:gap-6"
        }`}
      >
        {tags && topic.slug === "training-news" ? (
          <div className="space-x-5 flex my-6">{tagItems}</div>
        ) : (
          <div className="h-12" />
        )}
        <div className="flex space-x-3 sm:space-x-4">
          <div className="flex gap-2 space-x-2 items-baseline">
            <p className="leading-loose tracking-wide text-neutral-600 lg:text-lg">
              {updatedAt}更新
            </p>
            <p
              className={`text-xs neutral-600 lg:text-base ${
                updatedAt === createdAt ? "hidden" : "block"
              }`}
            >
              {createdAt}發佈
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6 aspect-video sm:mt-0">
        <GatsbyImage
          alt={name}
          image={gatsbyImageData}
          loading="eager"
          className="h-full w-full"
          objectFit="cover"
        />
      </div>
    </>
  )
}

Tag.propTypes = {
  tag: PropTypes.object,
}

Header.propTypes = {
  post: PropTypes.object,
}

export default Header

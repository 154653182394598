import React from "react"
import PropTypes from "prop-types"
import Link from "./Link"
import Button from "./Button"
import { useModalUpdate } from "../contexts/ModalContext"

//用於文章頁下方的CTA
const CTA = ({
  className = "",
  destination = "",
  name = "",
  excerpt = "",
  title = "立即諮詢",
}) => {
  const setIsModal = useModalUpdate()

  return (
    <div className={`mx-auto w-full py-6 ${className}`}>
      <div className="space-y-8 bg-neutral-100 py-8 px-6 sm:py-12">
        <p className="text-2xl !leading-tight text-center font-bold text-neutral-800 sm:text-4xl">
          {name}
        </p>
        {excerpt && (
          <p className="heading text-center text-neutral-600 text-lg !leading-loose tracking-wide mt-4 mb-8 sm:text-xl">
            {excerpt}
          </p>
        )}
        <div className="flex justify-center">
          {destination.includes("lin.ee") ? (
            <Button style="contact" isLink={false} onClick={setIsModal}>
              {title}
            </Button>
          ) : (
            <Link as="a" to={destination}>
              <Button style="contact">{title}</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

CTA.propTypes = {
  className: PropTypes.string,
  destination: PropTypes.string,
  name: PropTypes.string,
  excerpt: PropTypes.string,
  title: PropTypes.string,
}

export default CTA

import React from "react"
import PropTypes from "prop-types"
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share"
import FacebookMessenger from "../../images/icons8-facebook-messenger.svg"

const Share = ({ name, canonical }) => {
  return (
    <div className="flex gap-2">
      <p className="text-lg leading-loose text-neutral-600 mr-2.5 sm:mr-6 lg:mr-7">
        分享至：
      </p>
      <FacebookShareButton url={canonical} quote={name}>
        <FacebookIcon size={32} borderRadius={100} />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={canonical} quote={name}>
        <img src={FacebookMessenger} alt="FacebookMessenger" className="w-10" />
      </FacebookMessengerShareButton>
      <TelegramShareButton url={canonical} quote={name} ml="2">
        <TelegramIcon size={32} borderRadius={100} />
      </TelegramShareButton>
      <LineShareButton url={canonical} quote={name} ml="2">
        <LineIcon size={32} borderRadius={100} />
      </LineShareButton>
    </div>
  )
}

Share.propTypes = {
  name: PropTypes.string,
  canonical: PropTypes.string,
}

export default Share

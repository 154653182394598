import React from "react"
import PropTypes from "prop-types"

const Sidebar = ({
  popularItems,
  recommendedItems,
  hasBorder = true,
  isPostPage = false,
}) => {
  const hasBorderInTheMiddle = hasBorder
    ? "divide-neutral-300 divide-y"
    : "grid gap-8"

  return (
    <div
      className={`self-start sm:grid sm:gap-16 lg:grid-cols-1 lg:col-span-1 ${
        isPostPage ? "sm:grid-cols-1" : "sm:grid-cols-2"
      }`}
    >
      <div className="mt-24 border-t-4 border-amber-400 sm:mt-36 lg:mt-0">
        <p className="text-3xl leading-tight font-bold text-neutral-800 mt-3.5 mb-8 sm:mt-4">
          精選
        </p>
        <ul className={hasBorderInTheMiddle}>{recommendedItems}</ul>
      </div>
      <div
        className={`mt-16 border-t-4 border-amber-400 lg:mt-0 ${
          isPostPage ? "sm:mt-0" : "sm:mt-36"
        }`}
      >
        <p className="text-3xl leading-tight font-bold text-neutral-800 mt-3.5 mb-8 sm:mt-4">
          熱門
        </p>
        <ul className={hasBorderInTheMiddle}>{popularItems}</ul>
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  popularItems: PropTypes.array,
  recommendedItems: PropTypes.array,
  hasBorder: PropTypes.bool,
  isPostPage: PropTypes.bool,
}

export default Sidebar
